import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BlogPost } from '../../_models/index';
import { PageService } from '../../_services/index';
import { takeUntil } from 'rxjs/operators';
import { Subject} from 'rxjs';

@Component({
    selector: 'app-blog-home',
    templateUrl: './blog-home.component.html',
    styleUrls: ['./blog-home.component.scss'],
    standalone: false
})
export class BlogHomeComponent implements OnInit, OnDestroy {
    posts: BlogPost[] = [];
    private ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor (
        private title: Title,
        private pageService: PageService) {
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.title.setTitle('Solent Wholesale | Blog');
        this.getPosts();
    }

    getPosts() {
        this.pageService.getXBlogPosts(10).pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe(x => this.posts = x);
    }


}
