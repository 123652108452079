import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, } from '@angular/router';
import { PageService } from '../../_services/index';
import { BlogPost } from '../../_models/index';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-blog-item',
    templateUrl: './blog-item.component.html',
    styleUrls: ['./blog-item.component.scss'],
    standalone: false
})
export class BlogItemComponent implements OnInit, OnDestroy {
    blogSlug = null;
    blogPost: BlogPost = null;
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    constructor (
        private title: Title,
        private pageService: PageService,
        private route: ActivatedRoute) { }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }


    ngOnInit () {
        this.blogSlug = this.route.snapshot.paramMap.get('slug');
        this.getBlogPost(this.blogSlug);
    }

    getBlogPost(slug) {
        this.pageService.getOneBlogPost(slug).pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe(post => this.setBlogPost(post));
    }

    setBlogPost(post: BlogPost) {
        this.title.setTitle('Solent Wholesale | Blog ' + post.title);
        this.blogPost = post;
    }

}
