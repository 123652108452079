import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, } from '@angular/router';
import { PageService } from '../../_services/index';
import { BlogPost } from '../../_models/index';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-posts-by-tag',
    templateUrl: './posts-by-tag.component.html',
    styleUrls: ['./posts-by-tag.component.scss'],
    standalone: false
})
export class PostsByTagComponent implements OnInit, OnDestroy {
    posts: BlogPost[] = null;
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    constructor(
        private title: Title,
        private pageService: PageService,
        private route: ActivatedRoute) {
        this.route.params.subscribe(params => {
            const tagID = params.id;
            this.pageService.getBlogPostByTag(tagID).pipe(takeUntil(this.ngUnsubscribe)).subscribe(posts => {
                this.posts = posts;
            });
        });

    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.title.setTitle('Solent Wholesale | Blog Search');
    }




}
